
.person-menu {
    border: solid 1px;
    border-radius: 50px;
    padding: 2px 8px;
    margin-left: 15px;
}

.person-menu button {
    color: var(--text-color) !important;
}

.menu-paper {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

.menu-icon {
    margin-right: 8px;
}

.MuiMenuItem-root {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 160px;
}

.logout-menu-item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 4px;
    font-weight: bold;
    color: var(--orange)!important;
}

.logout-menu-item .menu-icon {
    color: var(--orange);
}