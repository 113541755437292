.backgroundContainer-landing {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: auto;
  }
  
  .backgroundContainer-landing::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../public/background_landing.jpg') no-repeat center center fixed;
    background-size: cover;
    opacity: 0.4;
    z-index: -1;
  }
  
  .landing-page-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
  }
  
  .logo-container {
    flex: 1;
  }
  
  .logo-fullsize {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
  
  .signup-login-container {
    flex: 1;
    margin-left: 5%;
    max-width: 500px;
  }
  
  @media (max-width: 1024px) {
    .landing-page-content {
      flex-direction: column;
      width: 100%;
    }
    .backgroundContainer-landing::before {
        position: fixed;
    }

    .logo {
        margin-top: 0px;
      }
  
    .signup-login-container {
      margin-left: 0;
      margin-top: 20px;
      max-width: 100%;
    }
  }