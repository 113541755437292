.my-profile-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-top: 50px;
    margin-bottom: 50px;
}

h1 {
    color: var(--orange);
    font-size: 2rem;
    text-align: center;
    margin-bottom: 15px !important;
}

.success-message {
    color: green;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--text-color);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
}

.form-group textarea {
    resize: vertical;
}

.save-button,
.view-profile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: var(--orange);
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.2s;
}

.view-profile-button {
    background-color: var(--text-color);
}

.save-button:hover {
    background-color: #b65109;
}

.view-profile-button:hover {
    background-color: #161d21;
}

.save-button svg,
.view-profile-button svg {
    margin-right: 8px;
}

@media (max-width: 1024px) {
    .my-profile-container {
        padding: 10px;
    }

    h1 {
        font-size: 1.5rem;
    }

    .form-group input,
    .form-group textarea {
        font-size: 0.9rem;
    }

    .save-button,
    .view-profile-button {
        font-size: 0.9rem;
    }
}