.myaccount-container {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.myaccount-sidebar {
  width: 250px;
  background-color: #f0f0f0;
  padding: 20px;
  border-right: 1px solid #ddd;
  transition: transform 0.3s ease;
}

.myaccount-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.myaccount-sidebar ul li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.myaccount-sidebar ul li:hover,
.myaccount-sidebar ul li.active {
  background-color: #ddd;
}

.myaccount-content {
  flex-grow: 1;
  padding: 40px;
}

.menu-icon {
  display: none;
  position: fixed;
  top: 100px;
  left: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
}

@media (max-width: 1024px) {
  .myaccount-container {
    flex-direction: column;
  }

  .myaccount-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 100;
    transform: translateX(-100%);
    width: 250px;
    transition: transform 0.3s ease;
    background-color: #f0f0f0;
    padding: 20px;
    border-right: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .myaccount-sidebar.open {
    transform: translateX(0);
  }

  .mobile-sidebar-header {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .menu-icon {
    display: block;
  }

  .close-icon {
    display: block;
    cursor: pointer;
  }

  .myaccount-content {
    padding: 20px;
    margin-top: 60px;
  }
}

.menu-icon.hidden {
  display: none;
}


@media (min-width: 1025px) {
  .menu-icon {
    display: none !important;
  }

  .MuiSvgIcon-root.close-icon {
    display: none !important;
  }
}