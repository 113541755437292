.terms-service-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: var(--text-color);
    margin-top: 35px;
    margin-bottom: 35px;
}

.terms-service-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: var(--orange);
}

.terms-service-container p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .terms-service-container {
        padding: 20px 10px;
    }

    .terms-service-container h1 {
        font-size: 2em;
    }

    .terms-service-container p {
        font-size: 1em;
    }
}


.terms-service-container a {
    color: var(--orange);
    font-size: 20px;
}