.not-found-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    text-align: center;
    padding: 20px;
    background-color: white;
    overflow: hidden;
}

.score-container {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 18px;
}

.score-text {
    color: var(--text-color);
}

.score-value {
    color: var(--orange);
}

.timer-container {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 18px;
}

.timer-text {
    color: var(--text-color);
}

.timer-value {
    color: var(--orange);
}

.hero-animation {
    position: absolute;
    width: 80px;
    height: 80px;
    transition: top 0.3s ease-out, left 0.3s ease-out;
}

.hero-image {
    width: 100%;
}

.target {
    position: absolute;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.target-image {
    width: 100%;
    height: 100%;
}

.text-container {
    margin-top: 200px;
}

.home-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--orange);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: darkorange;
}

@media (max-width: 1024px) {
    .not-found-container {
        padding: 10px;
        height: 65vh;
    }

    .hero-animation,
    .target {
        width: 60px;
        height: 60px;
    }

    .home-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}