#cc-main {
    --cc-font-family: 'Roboto', sans-serif;
    --cc-modal-border-radius: 8px;
    --cc-btn-border-radius: 4px;
    --cc-modal-transition-duration: 0.3s;

    --cc-btn-primary-bg: var(--orange);
    --cc-btn-primary-border-color: var(--orange);
    --cc-btn-primary-hover-bg: #e67e22;
    --cc-btn-primary-hover-border-color: #e67e22;
    --cc-btn-text-color: white;

    --cc-btn-secondary-bg: white;
    --cc-btn-secondary-border-color: var(--text-color);
    --cc-btn-secondary-text-color: var(--text-color);
    --cc-btn-secondary-hover-bg: #f5f5f5;
    --cc-btn-secondary-hover-border-color: var(--text-color);

    --cc-checkbox-bg: var(--orange);
    --cc-link-color: var(--orange);
    --cc-link-hover-color: #e67e22;

    --cc-modal-bg: white;
    --cc-modal-text-color: var(--text-color);

    --cc-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

#cc-main a {
    color: var(--orange) !important;
}

/*
TODO - Decide what looks best later.
#cc-main .toggle__icon {
    background: var(--orange) !important;
}*/

button[data-role="necessary"] {
    background: var(--text-color) !important;
    border: var(--text-color) !important;
}

@media (max-width: 768px) {
    #cc-main {
        --cc-modal-margin: 0.5rem;
    }
}