.email-password-section {
    padding: 20px;
    color: var(--text-color);
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  input:focus {
    border-color: var(--orange);
  }
  
  .orange-button {
    background-color: var(--orange);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .orange-button:hover {
    background-color: var(--orange-hover);
  }
  
  .message {
    font-size: 14px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
  }
  
  .message.error {
    color: #fff;
    background-color: var(--red-error);
  }
  
  .message.success {
    color: #fff;
    background-color: var(--green-success);
  }
  
  /* Mobile/Tablet Breakpoint */
  @media (max-width: 1024px) {
    .email-password-section {
      padding: 15px;
      max-width: 100%;
    }
  
    h2 {
      font-size: 1.5em;
    }
  
    input {
      font-size: 14px;
      padding: 8px;
    }
  
    .orange-button {
      font-size: 14px;
      padding: 8px;
    }
  
    .message {
      font-size: 12px;
      padding: 8px;
    }
  }