.my-offer-edit-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-offer-edit-container h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
    margin-right: 20px;
}

.form-group .description {
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    color: #666;
    margin-top: 2px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.form-group input,
.form-group textarea,
.form-group select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    background-color: white;
}

.form-group textarea {
    resize: vertical;
}

.form-group select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: right 8px top 50%;
    background-size: 24px 24px;
    padding-right: 32px;
    background-color: white !important;
}

.form-group input[type="datetime-local"] {
    color: var(--orange);
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
    border-color: var(--orange) !important;
    outline: none;
}

/*.form-group select option {
    background-color: var(--orange);
}*/

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: invert(42%) sepia(81%) saturate(1784%) hue-rotate(355deg) brightness(100%) contrast(101%);
}

.my-offer-edit-container button {
    padding: 10px 20px;
    margin-top: 15px;
    background-color: var(--orange);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.my-offer-edit-container button:hover {
    background-color: #e67e22;
}

.my-offer-edit-container button[type='button'] {
    background-color: #dc3545;
    margin-left: 10px;
}

.my-offer-edit-container button[type='button']:hover {
    background-color: #c82333;
}