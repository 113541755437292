.landing-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .landing-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../public/background_landing.jpg') no-repeat center center fixed;
    background-size: cover;
  }
  