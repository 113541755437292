.backgroundContainer-signup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
  }
  
  .form-container {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .password-container {
    display: flex;
    align-items: center;
  }
  
  .password-container input {
    flex-grow: 1;
  }
  
  .password-container i {
    cursor: pointer;
    margin-left: 10px;
  }
  
  button {
    margin-top: 20px;
  }

  .margin-top{
    margin-top: 10px !important;
  }
  
  .lightlink {
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .lightlink:hover {
    text-decoration: underline;
  }
  
  .message {
    color: red;
    margin-top: 10px;
  }
  