.mobile-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white;
}

.logo-link {
    display: flex;
    align-items: center;
}

.logoMobile {
    height: 60px;
}

.right-menu{
    display: flex;
    justify-content: end;
    margin-right: 20px;
}

.left-container {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allows it to grow and take up all the space except for the menu button */
}

.vertical-spacer {
    width: 1px;
    height: 40px;
    background-color: lightgrey;
    margin: 0px 20px 0px 10px;
}

.mobile-menu {
    width: 250px;
    padding: 20px;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-info {
    margin-bottom: 10px;
}

.search-bar-container {
    flex-grow: 0.5;
    max-width: 100%; /* Ensure search bar doesn't exceed available space */
}

.menu-button {
    width: 100%;
    justify-content: flex-start !important;
    color: var(--text-color) !important;
}

.login-button {
    background: var(--orange) !important;
    border: 1px solid var(--orange) !important;
    border-radius: 2px !important;
    color: white !important;
    width: 100%;
}

.menu-icon {
    margin-right: 10px;
}

.menu-button .MuiButton-label {
    justify-content: flex-start;
}

.logout-oange{
    color: var(--orange)  !important;
}