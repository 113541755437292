.navbar-container {
  width: 100%;
}

.top-bar {
  width: 970px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  margin: 0 auto;
}

.logo-container {}

.login-signup-container {}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 250px;
  height: 100px;
}

.search-bar-container {
  color: var(--text-color);
}

.search-bar-center {
  width: 970px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 0 auto;
}

.search-bar {
  background-color: var(--orange);
  display: flex;
  align-items: center;
}

.nav-button {
  margin-left: 10px;
  color: var(--text-color);
}

.search-bar-button {
  padding: 15px !important;
  background-color: white !important;
  color: var(--text-color) !important;
  margin-left: 10px !important;
}

.justify-right-side {
  justify-content: right;
}