.offer-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.offer-card h2 {
  margin: 0 0 10px;
  font-size: 1.5rem;
}

.offer-card p {
  margin: 5px 0;
}

.offer-card button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.offer-card button:hover {
  background-color: #218838;
}
