.profile-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.profile-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.profile-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

.profile-info {
    flex: 0;
    flex-grow: 1;
}

h1 {
    color: var(--orange);
    font-size: 1.6rem;
    margin: 0;
    text-align: left;
}

h2 {
    font-size: 1.2rem;
    color: var(--text-color);
    margin: 5px 0;
}

.profile-bio {
    margin: 10px 0;
    font-size: 1rem;
    color: var(--text-color);
}

.profile-joined {
    font-size: 0.9rem;
    color: var(--text-color);
    margin: 5px 0;
}

.profile-website {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: var(--text-color);
    margin: 5px 0;
}

.profile-website svg {
    margin-right: 5px;
    color: var(--orange);
}

.edit-profile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: var(--text-color);
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.2s;
}

.edit-profile-button:hover {
    background-color: #161d21;
}

.edit-profile-button svg {
    margin-right: 8px;
}

@media (max-width: 1024px) {
    .profile-container {
        padding: 10px;
    }

    .profile-header {
        flex-direction: column;
        align-items: center;
    }

    .profile-avatar {
        margin-bottom: 15px;
    }

    .profile-info {
        text-align: center;
    }

    h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    h2 {
        font-size: 1rem;
    }

    .profile-bio {
        font-size: 0.9rem;
    }

    .profile-joined,
    .profile-website {
        font-size: 0.8rem;
    }

    .profile-website {
        display: inline-flex;
    }

    .profile-avatar {
        margin-right: 0px;
    }
}