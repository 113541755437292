.language-switcher {
    display: flex;
    align-items: center;
    gap: 10px;
}

.language-form-control {
    min-width: 120px;
}

.language-switcher .MuiInputBase-root {
    &:hover fieldset {
        border-color: var(--orange) !important;
    }

    &.Mui-focused fieldset {
        border-color: var(--orange) !important;
    }
}

#translate-icon{
    margin-right: '8px';
}