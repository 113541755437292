.search-bar-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .search-input {
    background-color: white;
    border-radius: 5px;
    flex: 1;
  }
  
  .search-button {
    background-color: white;
    color: white;
  }
  