.my-offers-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;
}

.create-offer-button {
  display: block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: var(--orange);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.create-offer-button:hover {
  background-color: #e67e22;
}

.offers-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.my-offers-container h1{
  color: var(--text-color);
}