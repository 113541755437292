.footer-container {
    background-color: var(--text-color);
    color: white;
    padding: 20px 0;
    position: relative;
    text-align: center;
  }
  
  .footer-image {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: -4px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
  }
  
  .footer-link {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  .footer-copyright {
    margin-top: 10px;
  }